import './App.css';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import { blogJson, collectionJson, localeJson, menuJson, previewSectionsURL } from './constant';
import { Hash, Liquid, version } from 'liquidjs'
import { capitalize, isObject } from "lodash";

function App() {
    const [hideHeader, setHideHeader] = useState(false)
    const [emptyAction, setEmptyAction] = useState(true);
    const [empty, setEmpty] = useState(true)
    const [loading, setLoading] = useState(0)
    const [previewType, setPreviewType] = useState('');
    const [changeTemplate, setChangeTemplate] = ['index'];
    const [newKey, setNewKey] = useState('');
    //Fetch data


    //const origin = 'https://lid-deborah-westminster-adequate.trycloudflare.com';
    //const origin = 'https://app.smind.io'
    const origin = 'https://test.smind.io'

    const extensionUrl = 'https://cdn.shopify.com/extensions/69557aa7-6065-4fbb-82d0-5423092e071e/smind-sections-149/assets'
    const demoUrl = 'https://library.smind.io/cdn/shop/t/7/assets'

    //Excute js
    const executeAndRemoveScript = (script) => {
        if (script) {
            if (script.src) {
                let newScript = document.createElement('script');
                newScript.src = script.src;
                newScript.onload = function () {
                    script.parentNode.removeChild(script);
                };
                document.body.appendChild(newScript);
            } else {
                try {
                    let newScript = document.createElement('script');
                    newScript.textContent = script.textContent;
                    document.body.appendChild(newScript);
                    script.parentNode.removeChild(script);
                } catch { }
            }
        }
    }

    const getStyles = async (style) => {
        const response = await fetch(`${origin}/libraries/styles/${style}/style.smi`);
        return await response.text();
    }

    const getBlobUrl = async (html, style, needFetch = true) => {
        let data = ''
        if (!!needFetch && !style.includes('.smi-section')) {
            data = await getStyles(style)
        } else {
            data = style
        }

        let _html = html.replaceAll('"//', '"https://').replaceAll(' //', ' https://')
        _html = _html.replaceAll('smi-color-background-1', 'smi-color-scheme_1')
        _html = _html.replaceAll('smi-color-background-2', 'smi-color-scheme_2')
        _html = _html.replaceAll('smi-color-inverse', 'smi-color-scheme_3')
        _html = _html.replaceAll('smi-color-accent-1', 'smi-color-scheme_4')
        _html = _html.replaceAll('smi-color-accent-2', 'smi-color-scheme_5')

        const params = [
            `<style>
                body{padding: 0;margin: 0 !important;overflow: hidden !important;}
                .swiper-slide,
                .swiper-wrapper,
                .smi-item{
                    height: fit-content !important;
                }
                .shopify-cleanslate{display: none !important;}.swiper-wrapper{height: auto !important;}
                .shopify-payment-button__button{width: 100%; min-height: 45px;} .shopify-payment-button__more-options{display: none;}
            </style>`,
            `<style id="preview-style">${data}</style>`,
            `<script> 
                var Shopify = { designMode: false }  
                
                setTimeout(() => {
                    const buttons = document.querySelectorAll('.shopify-payment-button__button')
                    buttons.forEach(button => {
                        button.innerText = 'Buy it now'
                    })
                }, 2000)

                const handler = async (e) => {
                    if (e.data.type.includes('change-style')) {
                        const styleOld = document.querySelector('#preview-style')

                        if (styleOld) { 
                            styleOld.remove() 
                        }
                        const head = document.head || document.getElementsByTagName('head')[0]
                        const styleTag = document.createElement('style');
                        styleTag.id = 'preview-style'
                        head.appendChild(styleTag);
                        styleTag.type = 'text/css';
                        if (styleTag.styleSheet) {
                            styleTag.styleSheet.cssText = e.data.data;
                        } else {
                            styleTag.appendChild(document.createTextNode(e.data.data));
                        }
                    }                      
                }

                window.addEventListener('message', handler)
        
                document.addEventListener("DOMContentLoaded", (event) => {
                    const sectionEl = document.querySelector('.smi-section')
                    const id = sectionEl.parentNode.getAttribute('id')
                    const resizeObserver = new ResizeObserver(entries => {
                        for (let entry of entries) {
                            window.parent.postMessage({
                                type: ['change-height'],
                                data: {
                                    height: entry.contentRect.height,
                                    id: id
                                }
                            }, '*');
                        }
                    });

                    resizeObserver.observe(sectionEl);


                    const links = document.querySelectorAll('a:not(.smi-hook-lightbox)')
                    links.forEach(link => {
                        link.addEventListener('click', (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        })
                    })

                });    
                
                document.addEventListener("click", () => {
                    const sectionEl = document.querySelector('.smi-section')
                    const id = sectionEl.parentNode.getAttribute('id')
                    window.parent.postMessage({
                        type: ["iframe-clicked"],
                        data: {
                            id: id
                        }
                    }, "*");
                })  
            </script>
            
            <link href="${extensionUrl}/smi-base.min.css" rel="stylesheet" type="text/css" media="all">
            <script src="${extensionUrl}/smi-parallax.min.js" defer></script>
            <script src="${extensionUrl}/smi-animations.js" defer="defer"></script>
            <script src="${extensionUrl}/smi-base.min.js" type="text/javascript" defer="defer"></script>`,
            _html
        ]

        const blob = new Blob(params, { type: 'text/html' })
        const blobUrl = URL.createObjectURL(blob)

        return blobUrl
    }

    const createDivFrame = (blob, id) => {
        const div = document.createElement('div');
        div.setAttribute('id', id)
        div.classList.add('section')
        div.innerHTML = `<div class="inner"><iframe onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' src="${blob}" style="width: 100%; height: auto;border: 0; padding: 0;overflow: hidden !important;"></iframe></div>`;
        return div
    }

    const getPreviewDOM = async (previewUrl) => {
        const response = await fetch(previewUrl);
        const data = await response.text();
        const html = new DOMParser().parseFromString(data, 'text/html')
        const sections = html.querySelectorAll('#MainContent .smi-section > .smi-section__inner:not(.smi-prevent)')
        return sections
    }

    const getSectionHTML = async (url, preset, id) => {
        const sections = await getPreviewDOM(url)
        var el = document.createElement("div");
        el.id = id

        let section = sections?.[preset]
        if (!section) {
            section = sections[0]
        }
        el.appendChild(section.parentNode)
        return el.outerHTML
    }

    const getSectionPreviewUrl = (core, preset) => {
        const lists = ['featured-product-1', 'product-detail-1'];
        let path = ''
        if (!lists.includes(core)) {
            path = previewSectionsURL?.[core]
        } else {
            const _core = `${core}-${preset}`
            path = previewSectionsURL?.[_core]
        }

        if (path.includes('libraries/previews')) {
            return path
        } else {
            return `https://library.smind.io/${path}`;
        }
    }

    const appendDivToGroup = (group, div) => {
        const groupEl = document.getElementById(group + '-group')
        if (groupEl) {
            groupEl.appendChild(div)
        }
    }

    const getInsertDivData = async (section, isTemplate = false, keyRender = '') => {
        if (!!keyRender && !!keyssFo && (keyssFo !== keyRender)) {
            return null;
        }
        try {
        const productCores = ['featured-product-1', 'product-detail-1'];
        const collectionCores = ['collection-page-1'];
        const blogPageCores = ['blog-page-1'];
        const blogPostCores = ['blog-post-article-1'];
        const productTabCores = ['product-info-tabs-1'];
        const reviewCores = ['review-1', 'review-2', 'review-3', 'review-4'];
        const shopTheLookCores = ['shop-the-look-1'];
        const productBundlesCores = ['product-bundles-1', 'related-products-1'];
        const recentlyViewedCores = ['recently-viewed-1'];
        const collectionListPageCores = ['collection-list-page-1'];

        //Global object
        let globalProduct = {};
        let globalCollection = {};
        let globalLinklists = {};
        let globalBlogs = {};
        let globalBlog = {};
        let globalArticle = {};
        let globalRecommendations = {};
        let globalSearch = {};
        let globalCollections = {};

        if (collectionCores.includes(section.core)) {

            globalCollection = {
                products_count: 34,
                all_products_count: 150,
                filters: [
                    {
                        label: 'Availability',
                        type: 'list',
                        values: [
                            {
                                label: 'In stock',
                                param_name: 'availability',
                                value: 'in_stock',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Out of stock',
                                param_name: 'availability',
                                value: 'out_of_stock',
                                active: false,
                                count: 29
                            }
                        ]
                    },
                    {
                        label: 'Price',
                        type: 'price_range',
                        range_max: 99800
                    },
                    {
                        label: 'Tags',
                        type: 'list',
                        param_name: 'tags',
                        values: [
                            {
                                label: 'Bags',
                                param_name: 'tags',
                                value: 'bags',
                                active: false,
                                count: 1
                            },
                            {
                                label: 'Classic',
                                param_name: 'Classic',
                                value: 'Classic',
                                active: false,
                                count: 34
                            },
                            {
                                label: 'Dresses',
                                param_name: 'Dresses',
                                value: 'Dresses',
                                active: false,
                                count: 7
                            },
                            {
                                label: 'Jackets',
                                param_name: 'Jackets',
                                value: 'Jackets',
                                active: false,
                                count: 2
                            },
                            {
                                label: 'Jeans',
                                param_name: 'Jeans',
                                value: 'Jeans',
                                active: false,
                                count: 7
                            },
                            {
                                label: 'Shirts',
                                param_name: 'Shirts',
                                value: 'Shirts',
                                active: false,
                                count: 9
                            },
                            {
                                label: 'Suits',
                                param_name: 'Suits',
                                value: 'Suits',
                                active: false,
                                count: 8
                            },
                            {
                                label: 'Women',
                                param_name: 'Women',
                                value: 'Women',
                                active: false,
                                count: 33
                            }
                        ]
                    },
                    {
                        label: 'Color',
                        type: 'list',
                        values: [
                            {
                                label: 'AliceBlue',
                                param_name: 'AliceBlue',
                                value: 'AliceBlue',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Beige',
                                param_name: 'Beige',
                                value: 'Beige',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Black',
                                param_name: 'Black',
                                value: 'Black',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Blue',
                                param_name: 'Blue',
                                value: 'Blue',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Brown',
                                param_name: 'Brown',
                                value: 'Brown',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Chocolate',
                                param_name: 'Chocolate',
                                value: 'Chocolate',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Coral',
                                param_name: 'Coral',
                                value: 'Coral',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'DarkGreen',
                                param_name: 'DarkGreen',
                                value: 'DarkGreen',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'DarkOliveGreen',
                                param_name: 'DarkOliveGreen',
                                value: 'DarkOliveGreen',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'DimGray',
                                param_name: 'DimGray',
                                value: 'DimGray',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'FloralWhite',
                                param_name: 'FloralWhite',
                                value: 'FloralWhite',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Gray',
                                param_name: 'Gray',
                                value: 'Gray',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Green',
                                param_name: 'Green',
                                value: 'Green',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'HoneyDew',
                                param_name: 'HoneyDew',
                                value: 'HoneyDew',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'HotPink',
                                param_name: 'HotPink',
                                value: 'HotPink',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Khaki',
                                param_name: 'Khaki',
                                value: 'Khaki',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'LightBlue',
                                param_name: 'LightBlue',
                                value: 'LightBlue',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'LightPink',
                                param_name: 'LightPink',
                                value: 'LightPink',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'LightYellow',
                                param_name: 'LightYellow',
                                value: 'LightYellow',
                                active: false,
                                count: 33
                            },
                            {
                                label: 'Linen',
                                param_name: 'Linen',
                                value: 'Linen',
                                active: false,
                                count: 33
                            }
                        ]
                    }
                ],
                sort_by: 'best-selling',
                sort_options: [
                    {
                        value: 'manual',
                        name: 'Featured',
                    },
                    {
                        value: 'best-selling',
                        name: 'Best selling',
                    },
                    {
                        value: 'title-ascending',
                        name: 'Alphabetically, A-Z',
                    },
                    {
                        value: 'title-descending',
                        name: 'Alphabetically, Z-A',
                    },
                    {
                        value: 'price-ascending',
                        name: 'Price, low to high',
                    },
                    {
                        value: 'price-descending',
                        name: 'Price, high to low',
                    },
                    {
                        value: 'created-ascending',
                        name: 'Date, old to new',
                    },
                    {
                        value: 'created-descending',
                        name: 'Date, new to old',
                    },
                ]
            };

            const collectionHandle = 'collection-page-1';
            const url = `${origin}/libraries/partials/json/collections/${collectionHandle}.json`;
            const response = await fetch(url);
            if (response.status === 200) {
                let jsonData = await response.json();
                globalCollection.products = jsonData.map(product => {
                    return {
                        ...product,
                        featured_media: product.featured_image,
                        selected_or_first_available_variant: product.variants[0]
                    }
                })
            }
        }

        if (blogPageCores.includes(section.core)) {
            const blogHandle = 'blog-page';
            const url = `${origin}/libraries/partials/json/blogs/${blogHandle}.json`;
            const response = await fetch(url);
            let jsonData = await response.json();

            globalBlog = {
                all_tags: [
                    'Fashion', 'Fashion Designer', 'Style', 'Stylist', 'Trending', 'Vintage'
                ],
                articles: jsonData
            }
        }

        if (blogPostCores.includes(section.core)) {
            const blogHandle = 'the-easy-summer-uniform-i-keep-coming-back-to';
            const url = `${origin}/libraries/partials/json/posts/${blogHandle}.json`;
            const response = await fetch(url);
            let jsonData = await response.json();

            globalArticle = {
                ...jsonData,
                handle: 'Blog-page',
                content: jsonData.body_html
            };

            globalBlog = {
                ...globalBlog,
                all_tags: [
                    'Fashion', 'Fashion Designer', 'Style', 'Stylist', 'Trending', 'Vintage'
                ],
                previous_article: {
                    url: '#',
                    title: 'Is this the affordable answer to The Row’s Margaux Tote?'
                },
                next_article: {
                    url: '#',
                    title: 'My best & worst purchases of 2023'
                },
                'comments_enabled?': true
            }
        }

        if (productTabCores.includes(section.core)) {
            const productHandle = 'bodhi-fisher-sneakers';
            const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
            const response = await fetch(url);
            let jsonData = await response.json();

            globalProduct = {
                ...globalProduct,
                ...jsonData
            }
        }

        if (productBundlesCores.includes(section.core)) {
            let _products = [];
            const productHandles = [
                'the-ragged-priest-goliath-unisex-jeans',
                'we-the-free-final-countdown-cuffed-low-rise-jeans',
                'we-the-free-crossroads-mid-rise-boyfriend-jeans',
                'copy-of-the-ragged-unisex-jeans',
            ];
            if (productHandles) {
                for (let i = 0; i < productHandles.length; i++) {
                    const productHandle = productHandles[i];
                    const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
                    const response = await fetch(url);
                    let jsonData = await response.json();
                    _products.push(jsonData);
                }
            }

            globalRecommendations = {
                products_count: 4,
                products: _products,
                performed: true
            }
        }

        if (recentlyViewedCores.includes(section.core)) {
            let _products = [];
            const productHandles = [
                'the-ragged-priest-goliath-unisex-jeans',
                'we-the-free-final-countdown-cuffed-low-rise-jeans',
                'we-the-free-crossroads-mid-rise-boyfriend-jeans',
                'copy-of-the-ragged-unisex-jeans',
            ];
            if (productHandles) {
                for (let i = 0; i < productHandles.length; i++) {
                    const productHandle = productHandles[i];
                    const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
                    const response = await fetch(url);
                    let jsonData = await response.json();
                    _products.push(jsonData);
                }
            }

            globalSearch = {
                results_count: 4,
                results: _products
            }
        }

        if (collectionListPageCores.includes(section.core)) {
            globalCollections = [
                {
                    url: '#',
                    title: 'Bag',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-bags-classic-17.webp?v=1711521291&width=1200',
                    all_products_count: 2
                },
                {
                    url: '#',
                    title: 'Bags',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-bags-classic-01.webp?v=1711521293&width=1200',
                    all_products_count: 6
                },
                {
                    url: '#',
                    title: 'Ballet',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-shoes-classic-22.webp?v=1711521296&width=1200',
                    all_products_count: 2
                },
                {
                    url: '#',
                    title: 'Boots',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-shoes-classic-04.webp?v=1711521307&width=1200',
                    all_products_count: 4
                },
                {
                    url: '#',
                    title: 'Cardigan',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-jacket-classic-18.webp?v=1711521312&width=1200',
                    all_products_count: 12
                },
                {
                    url: '#',
                    title: 'Coats',
                    featured_image: 'https://library.smind.io/cdn/shop/collections/smi-jacket-classic-05.webp?v=1711521321&width=1200',
                    all_products_count: 11
                }
            ]
        }

        let url = `${origin}/libraries/sections/${section.core}/index.liquid`;
        if(section.core === 'subtotal-1') {
           url = `${origin}/libraries/sections/${section.core}/index-1.liquid`;
        }
        
        const response = await fetch(url);
        let liquidText = await response.text();

        liquidText = liquidText.replaceAll(`{% render block %}`, '');
        liquidText = liquidText.replaceAll(`{{ block.shopify_attributes }}`, '');

        let settings = {
            smi_social_facebook_link: '#',
            smi_social_instagram_link: '',
            smi_social_youtube_link: '',
            smi_social_tiktok_link: '',
            smi_social_twitter_link: '#',
            smi_social_spanchat_link: '',
            smi_social_pinterest_link: '#',
            smi_social_tumblr_link: '',
            smi_social_vimeo_link: '#',
            smi_color_variant_name: 'Color'
        };
        let liquidJson = {};
        if (section?.data) {
            liquidJson = section.data;
        } else {
            const urlJson = `${origin}/libraries/sections/${section.core}/presets/preset-${section.preset}/classic-1/clothing/index.json`;
            const responseJson = await fetch(urlJson);
            liquidJson = await responseJson.json();
        }

        liquidJson.id = section.randomId

        //Parse json for product
        if (section.core === 'product-detail-1') {
            if (section.id === 'product-detail-1-1') { liquidJson.settings.product = 'we-the-free-cuddle-v-neck-sweater'; }
            if (section.id === 'product-detail-1-2') { liquidJson.settings.product = 'all-that-sweatshirt'; }
            if (section.id === 'product-detail-1-3') { liquidJson.settings.product = 'copy-of-kamali-boot-pant-suit'; }
            if (section.id === 'product-detail-1-4') { liquidJson.settings.product = 'copy-of-fenty-skin-travel'; }
            if (section.id === 'product-detail-1-5') { liquidJson.settings.product = 'true-wireless-headphones'; }
            if (section.id === 'product-detail-1-6') { liquidJson.settings.product = 'rolo-chain-charm-necklace'; }
            if (section.id === 'product-detail-1-7') { liquidJson.settings.product = 'lucas-tub-chair'; }
            if (section.id === 'product-detail-1-8') { liquidJson.settings.product = 'fine-grooves-vase'; }
            if (section.id === 'product-detail-1-9') { liquidJson.settings.product = 'duramo-running-shoes'; }
            if (section.id === 'product-detail-1-10') { liquidJson.settings.product = 'energy-drink-hawaiian'; }
            if (section.id === 'product-detail-1-11') { liquidJson.settings.product = 'hardy-houseplants'; }
            if (section.id === 'product-detail-1-12') { liquidJson.settings.product = 'fraxinus-dashe'; }
            if (section.id === 'product-detail-1-13') { liquidJson.settings.product = 'little-women'; }
        }


        let blocks = liquidJson.blocks;
        let blockTemp = []
        let stt = 0;
        let sttCollection = 0;

        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];

            if (block.type !== 'color' && block.type !== 'custom_color') {
                let _item = {
                    ...block,
                    id: section.randomId + '-block-' + i
                }

                if (block.type === 'menu') {
                    _item.settings = {
                        ..._item.settings,
                        menu: menuJson.clothing?.[`menu_${stt}`]
                    }

                    stt++;
                }

                if (block.type === 'collection') {
                    _item.settings = {
                        ..._item.settings,
                        collection: collectionJson?.[sttCollection]
                    }
                    sttCollection++;
                }

                if (block.type === 'countdown_timer') {
                    _item.settings = {
                        ..._item.settings,
                        end_date_time: '2025-06-30'
                    }
                }

                if (block.type === 'colection_tab') {
                    const collectionHandle = _item?.settings?.collection;
                    const url = `${origin}/libraries/partials/json/collections/${collectionHandle}.json`;
                    const response = await fetch(url);
                    if (response.status === 200) {
                        let jsonData = await response.json();
                        let collectionTitle = collectionHandle.replace('-1', '');
                        collectionTitle = collectionTitle.replace('-2', '');
                        collectionTitle = collectionTitle.replace('-3', '');
                        collectionTitle = collectionTitle.replace('-', ' ');

                        _item.settings = {
                            ..._item.settings,
                            collection: {
                                title: capitalize(collectionTitle),
                                products: jsonData.map(product => {
                                    return {
                                        ...product,
                                        featured_media: product.featured_image,
                                        selected_or_first_available_variant: product.variants[0]
                                    }
                                })
                            }
                        }

                        liquidText = liquidText.replaceAll('product.media[1]', 'product.media[1].preview_image.src');
                        liquidText = liquidText.replaceAll('product.featured_media.aspect_ratio', 'product.media[0].aspect_ratio');
                    }
                }

                if (block.type === 'mega_collection') {
                    _item.settings = {
                        ..._item.settings,
                        collection_list: collectionJson.slice(0, 8)
                    }
                }

                if (block.type === 'categories') {
                    globalLinklists = {
                        [_item.settings.menu]: menuJson.clothing?.[`menu-2`]
                    }
                }

                if (block.type === 'recent_post') {
                    const blogHandle = _item?.settings?.blog;
                    const url = `${origin}/libraries/partials/json/blogs/${blogHandle}.json`;
                    const response = await fetch(url);
                    let jsonData = await response.json();

                    globalBlogs = {
                        ...globalBlogs,
                        [blogHandle]: {
                            articles: jsonData
                        }
                    }

                    let blogTitle = blogHandle.replace('-1', '');
                    blogTitle = blogTitle.replace('-2', '');
                    blogTitle = blogTitle.replace('-3', '');
                    blogTitle = blogTitle.replace('-', ' ');
                    _item.settings = {
                        ..._item.settings,
                        blog: {
                            title: capitalize(blogTitle),
                            handle: blogHandle
                        }
                    }
                }

                if (block.type === 'popular_product') {
                    let _products = [];
                    const productHandles = _item?.settings?.products;
                    if (productHandles) {
                        for (let i = 0; i < productHandles.length; i++) {
                            const productHandle = productHandles[i];
                            const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
                            const response = await fetch(url);
                            let jsonData = await response.json();
                            _products.push(jsonData);
                        }
                    }

                    _item.settings = {
                        ..._item.settings,
                        products: _products
                    }
                }

                if (block.type === 'custom_tab' && productTabCores.includes(section.core)) {
                    const pageHandle = _item?.settings?.content_page;
                    const url = `https://library.smind.io/pages/${pageHandle}?view=smi-data`;
                    const response = await fetch(url);
                    let jsonData = await response.json();

                    _item.settings = {
                        ..._item.settings,
                        content_page: jsonData
                    }
                }

                if (reviewCores.includes(section.core)) {
                    if (_item?.settings?.product) {
                        _item.settings = {
                            ..._item.settings,
                            product: {
                                featured_media: 'https://library.smind.io/cdn/shop/files/smi-shirt-classic-333.webp?v=1712806787&width=150&height=50',
                                url: '#',
                                title: 'All that sweatshirt'
                            }
                        }
                    }
                }

                if (shopTheLookCores.includes(section.core)) {
                    if (block.type === 'look') {
                        let _products = [];
                        const productHandles = [];
                        if (_item?.settings?.product_1 !== '') {
                            productHandles.push(_item?.settings?.product_1);
                        }

                        if (_item?.settings?.product_2 !== '') {
                            productHandles.push(_item?.settings?.product_2);
                        }

                        if (_item?.settings?.product_3 !== '') {
                            productHandles.push(_item?.settings?.product_3);
                        }

                        if (_item?.settings?.product_4 !== '') {
                            productHandles.push(_item?.settings?.product_4);
                        }

                        if (productHandles) {
                            for (let i = 0; i < productHandles.length; i++) {
                                const productHandle = productHandles[i];
                                const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
                                const response = await fetch(url);
                                let jsonData = await response.json();
                                _products.push(jsonData);
                            }
                        }

                        _item.settings = {
                            ..._item.settings,
                            product_1: _products?.[0] ? _products?.[0] : '',
                            product_2: _products?.[1] ? _products?.[1] : '',
                            product_3: _products?.[2] ? _products?.[2] : '',
                            product_4: _products?.[3] ? _products?.[3] : '',
                        }
                    }
                }

                if (block.type === 'video_block') {
                    if (_item?.settings?.video_url) {
                        let videoTemp = _item?.settings?.video_url;
                        videoTemp = videoTemp.replace('https://youtu.be/', '');
                        _item.settings = {
                            ..._item.settings,
                            video_url: {
                                type: 'youtube',
                                id: videoTemp
                            }
                        }
                    }
                }

                blockTemp.push(_item);
            }
        }

        liquidJson.blocks = blockTemp;

        if (liquidJson?.settings?.video_url) {
            let videoTemp = liquidJson?.settings?.video_url;
            videoTemp = videoTemp.replace('https://youtu.be/', '');
            liquidJson.settings.video_url = {
                type: 'youtube',
                id: videoTemp
            }
        }

        if (liquidJson?.settings?.blog) {
            liquidJson.settings.blog = blogJson
        }

        if (liquidJson?.settings?.collection) {
            const collectionHandle = liquidJson?.settings?.collection;
            const url = `${origin}/libraries/partials/json/collections/${collectionHandle}.json`;
            const response = await fetch(url);
            if (response.status === 200) {
                let jsonData = await response.json();
                liquidJson.settings.collection = {
                    url: `https://library.smind.io/collections/${collectionHandle}`,
                    products: jsonData.map(product => {
                        return {
                            ...product,
                            featured_media: product.featured_image,
                            selected_or_first_available_variant: product.variants[0]
                        }
                    })
                }

                settings = {
                    ...settings
                }

                liquidText = liquidText.replaceAll('product.media[1]', 'product.media[1].preview_image.src');
                liquidText = liquidText.replaceAll('product.featured_media.aspect_ratio', 'product.media[0].aspect_ratio');
            }
        }

        if (liquidJson?.settings?.menu) {
            liquidJson.settings.menu = menuJson.clothing?.[`menu_header_1`]
        }


        if (liquidJson?.settings?.product) {
            const productHandle = liquidJson?.settings?.product;
            const url = `https://library.smind.io/products/${productHandle}?view=smi-data`;
            const response = await fetch(url);
            let jsonData = await response.json();
            liquidJson.settings.product = {
                ...jsonData
            }

            globalProduct = jsonData;
        }



        liquidText = liquidText.replaceAll('{% style %}', '<style>');
        liquidText = liquidText.replaceAll('{% endstyle %}', '</style>');


        if (liquidText.includes('smi-background')) {
            liquidText = liquidText.replaceAll("{% render 'smi-background' %}", `{% render 'partials/snippets/smi-background.liquid', section: section %}`);
        }

        if (productCores.includes(section.core)) {
            if (liquidText.includes('smi-general-block')) {
                liquidText = liquidText.replaceAll("{% render 'smi-general-block'", `{% render 'partials/snippets/product/smi-general-block.liquid'`);
            }

            if (liquidText.includes('smi-product-custom-buy-button')) {
                liquidText = liquidText.replaceAll("{% render 'smi-product-custom-buy-button'", `{% render 'partials/snippets/product/smi-product-custom-buy-button.liquid'`);
            }

            if (liquidText.includes('smi-complementary-products')) {
                liquidText = liquidText.replaceAll("{% render 'smi-complementary-products'", `{% render 'partials/snippets/product/smi-complementary-products.liquid'`);
            }

            if (liquidText.includes('smi-bundle-products')) {
                liquidText = liquidText.replaceAll("{% render 'smi-bundle-products'", `{% render 'partials/snippets/product/smi-bundle-products.liquid'`);
            }

            if (liquidText.includes('smi-product-description')) {
                liquidText = liquidText.replaceAll("{% render 'smi-product-description'", `{% render 'partials/snippets/product/smi-product-description.liquid'`);
            }

            if (liquidText.includes('smi-product-collapse-content')) {
                liquidText = liquidText.replaceAll("{% render 'smi-product-collapse-content'", `{% render 'partials/snippets/product/smi-product-collapse-content.liquid'`);
            }

            if (liquidText.includes('smi-product-ask-question')) {
                liquidText = liquidText.replaceAll("{% render 'smi-product-ask-question'", `{% render 'partials/snippets/product/smi-product-ask-question.liquid'`);
            }

            if (liquidText.includes('smi-product-breadcrumb')) {
                liquidText = liquidText.replaceAll("{% render 'smi-product-breadcrumb'", `{% render 'partials/snippets/product/smi-product-breadcrumb.liquid'`);
            }
        }

        const regex1 = /\{%- render 'smi-custom-icons', icon: block.settings\.([a-zA-Z0-9_]+) -%\}/g;
        liquidText = liquidText.replace(regex1, (match, p1) => {
            return `<custom-icons custom="true" source="{{ block.settings.${p1} }}"></custom-icons>`;
        });

        const regex1_1 = /\{% render 'smi-custom-icons', icon: '([^']*)' %\}/g;
        liquidText = liquidText.replace(regex1_1, (match, p1) => {
            return `<custom-icons custom="true" source="${p1}"></custom-icons>`;
        });

        const regex1_2 = /\{% render 'smi-icons', icon: i %\}/g;
        liquidText = liquidText.replace(regex1_2, (match, p1) => {
            return `<custom-icons source="{{ i }}"></custom-icons>`;
        });

        const regex2 = /\{% render 'smi-icons', icon: '([^']*)' %\}/g;
        liquidText = liquidText.replace(regex2, (match, p1) => {
            return `<custom-icons source="${p1}"></custom-icons>`;
        });

        const regex2_1 = /\{%- render 'smi-icons', icon: '([^']*)' -%\}/g;
        liquidText = liquidText.replace(regex2_1, (match, p1) => {
            return `<custom-icons source="${p1}"></custom-icons>`;
        });

        const regex3 = /\{%- render 'smi-icons', icon: ([a-zA-Z0-9_.]+) -%\}/g;
        liquidText = liquidText.replace(regex3, (match, p1) => {
            return `<custom-icons source="{{ ${p1} }}"></custom-icons>`;
        });

        const regex4 = /\{\% schema \%\}[\s\S]*?\{\% endschema \%\}/g;
        liquidText = liquidText.replace(regex4, '');

        const regex5 = /\{\% comment \%\}[\s\S]*?\{\% endcomment \%\}/g;
        liquidText = liquidText.replace(regex5, '');

        liquidText = liquidText.replaceAll("render 'smi-icons'", `render 'partials/snippets/smi-icons.liquid'`);
        liquidText = liquidText.replaceAll("render 'smi-custom-icons'", `render 'partials/snippets/smi-custom-icons.liquid'`);
        liquidText = liquidText.replaceAll("render 'smi-flags'", `render 'partials/snippets/smi-flags.liquid'`);

        const engine = new Liquid();

        engine.registerFilter('at_least', (data, arg1, arg2, arg3) => {
            let num1 = data;
            let num2 = arg1;
            if(!num1) {
                num1 = 10;
            }
            return num1 < num2 ? num2 : num1;
        })

        engine.registerFilter('handle', (data, arg1, arg2, arg3) => {
            return data.toLowerCase().replaceAll(' ', '-');
        })

        engine.registerFilter('money', (data, arg1, arg2, arg3) => {
            return '$' + (data / 100).toFixed(2);
        })

        engine.registerFilter('money_without_currency', (data, arg1, arg2, arg3) => {
            return (data / 100).toFixed(2);
        })

        engine.registerFilter('money_with_currency', (data, arg1, arg2, arg3) => {
            return '$' + (data / 100).toFixed(2);
        })

        engine.registerFilter('append', (data, arg1, arg2, arg3) => {
            return data + arg1;
        })

        engine.registerFilter('image_url', (data, arg1, arg2, arg3) => {
            let _data = data;

            if (isObject(data)) {
                if (data?.preview_image) {
                    _data = data?.preview_image?.src;
                }

                if (data?.src) {
                    _data = data?.src;
                }
            }
            let imgUrl = '';

            if (_data.includes('library.smind.io') || !!isTemplate) {
                if (!!isTemplate) {
                    imgUrl = _data.replace('shopify://shop_images/', 'https://library.smind.io/cdn/shop/files/')
                } else {
                    imgUrl = _data;
                }

                //Replace
                if (imgUrl.includes('1._Pants-Topper-1.jpg')) {
                    imgUrl = imgUrl.replace('.jpg', '.webp');
                }
                if (imgUrl.includes('2._Pants-Topper-2.jpg')) {
                    imgUrl = imgUrl.replace('.jpg', '.webp');
                }
                if (imgUrl.includes('4.Pants-Topper-4.jpg')) {
                    imgUrl = imgUrl.replace('.jpg', '.webp');
                }
            } else {
                const arrImage = _data.split('/');
                imgUrl = `https://app.smind.io/libraries/sections/${section.core}/presets/preset-${section.preset}/classic-1/clothing/resources/${arrImage[arrImage.length - 1]}`
            }

            return imgUrl;
        })

        engine.registerFilter('image_tag', (data, arg1, arg2, arg3) => {
            let _data = data;
            let classes = '';
            if (arg1) { if (arg1.includes('class')) { classes = arg1[1] } }
            if (arg2) { if (arg2.includes('class')) { classes = arg2[1] } }
            if (arg3) { if (arg3.includes('class')) { classes = arg3[1] } }

            ///section.settings.upload_image
            if (isObject(data)) {
                if (data?.preview_image) {
                    _data = data?.preview_image?.src;
                }
            }
            let imgUrl = '';
            if (_data.includes('library.smind.io') || !!isTemplate) {
                if (!!isTemplate) {
                    imgUrl = _data.replace('shopify://shop_images/', 'https://library.smind.io/cdn/shop/files/')
                } else {
                    imgUrl = _data;
                }
            } else {
                const arrImage = _data.split('/');
                imgUrl = `https://app.smind.io/libraries/sections/${section.core}/presets/preset-${section.preset}/classic-1/clothing/resources/${arrImage[arrImage.length - 1]}`
            }

            return `<img src="${imgUrl}" class="${classes}"/>`
        })

        engine.registerFilter('link_to_tag', (data, arg1, arg2, arg3) => {
            return `<a href="#tag">${data}</a>`
        })

        engine.registerFilter('asset_url', (data, arg1, arg2, arg3) => {
            return `https://library.smind.io/cdn/shop/t/7/assets/${data}`;
        })

        engine.registerFilter('stylesheet_tag', (data, arg1, arg2, arg3) => {
            return `<link href="${data}" rel="stylesheet" type="text/css" media="all">`;
        })

        engine.registerFilter('default_pagination', (data, arg1, arg2, arg3) => {
            return `<span class="page current">1</span>
                <span class="page"><a href="/collections/collection-page-1?page=2" title="">2</a></span>
                <span class="page"><a href="/collections/collection-page-1?page=3" title="">3</a></span>
                <span class="next"><a href="/collections/collection-page-1?page=2" title="" aria-label="next">
                    <svg class="smi-svg-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.946 19.89c-.015-.165.105-.295.23-.42l4.473-4.468c.585-.584 1.145-1.194 1.755-1.744.83-.745.804-1.744.01-2.514-2.1-2.049-4.154-4.138-6.228-6.212-.095-.095-.2-.185-.225-.32-.045-.235.015-.435.225-.56a.476.476 0 0 1 .6.055c.05.045.095.095.14.14l6.342 6.342c1.045 1.045 1.05 2.57.01 3.609L8.916 20.16c-.045.045-.09.095-.145.14a.472.472 0 0 1-.535.08c-.185-.09-.285-.245-.29-.49Z" fill="#1F1F21"></path></svg>	
                </a></span>
            `;
        })

        engine.registerFilter('t', (data, arg1, arg2, arg3) => {
            const localeKey = data.split('.');

            let localeStr = '';
            if (localeKey.length === 3) {
                localeStr = localeJson?.[localeKey[1]]?.[localeKey[2]];
            }
            if (localeKey.length === 4) {
                localeStr = localeJson?.[localeKey[1]]?.[localeKey[2]]?.[localeKey[3]];
            }
            if (data.includes('product_count')) {
                localeStr = localeJson?.[localeKey[1]]?.[localeKey[2]].other;
                localeStr = localeStr.replace('{{ product_count }}', 34);
            }

            if (data.includes('order_page.title')) {
                localeStr = localeStr.replace('{{ name }}', '#1007');
            }

            if (data.includes('order_page.date_html')) {
                localeStr = localeStr.replace('{{ date }}', 'June 20, 2024 at 4:08 am');
            }

            if (isObject(localeStr)) {
                if (localeStr.hasOwnProperty('other')) {
                    localeStr = localeStr.other;
                    if (localeStr.includes('product_count')) {
                        const _count = arg1?.[1];
                        localeStr = localeStr.replace('{{ product_count }}', _count);
                    }
                }
            }

            return localeStr;
        })

        engine.registerFilter('format_address', (data, arg1, arg2, arg3) => {
            return `<p>Smind App<br>Ha Noi<br>Ha Noi 100000<br>Vietnam</p>`;
        })


        engine.registerTag('form', {
            parse: function (tagToken) {
                this.classes = '';
                this.id = '';
                if (tagToken.args.includes(':')) {
                    const formAttrs = tagToken.args.split(',')
                    formAttrs.map(item => {
                        if (item.includes('class:')) {
                            const temp = item.split(':');
                            if (temp.length === 2) {
                                this.classes = temp[1].trim();
                            }
                        }

                        if (item.includes('id:')) {
                            const temp = item.split(':');
                            if (temp.length === 2) {
                                this.id = temp[1].trim();
                            }
                        }
                    })
                }
            },

            render: function (ctx, emitter) {
                emitter.write(`<form class=${this.classes} id=${this.id}>`);
            }
        });

        engine.registerTag('endform', {
            render: function (ctx, emitter) {
                emitter.write(`</form>`);
            }
        });

        engine.registerTag('render_icon', {
            parse: function (tagToken) {
                let icons = tagToken.args.split(':');
                this.icon = icons[1].trim().replaceAll(`'`, '');
            },

            render: function (ctx, emitter) {
                emitter.write(`<custom-icons source="${this.icon}"></custom-icons>`);
            }
        });

        engine.registerTag('paginate', {
            parse: function (tagToken) { },

            render: function (ctx, emitter) {
                emitter.write(``);
            }
        });

        engine.registerTag('endpaginate', {
            parse: function (tagToken) { },

            render: function (ctx, emitter) {
                emitter.write(``);
            }
        });

        const tpl = engine.parse(liquidText)

        let html = await engine.renderSync(tpl, {
            settings,
            powered_by_link: 'Smind Powered by Shopify',
            template: 'page',
            section: liquidJson,
            shop: {
                name: "logo",
                url: 'https://library.smind.io'
            },
            cart: {
                item_count: 4,
                currency: {
                    symbol: '$'
                },
                items: [
                    {
                        image: 'https://library.smind.io/cdn/shop/files/smi-bak-5042.jpg?v=1717645841&width=80',
                        product: {
                            title: '2-Pack dots bibs taupe'
                        },
                        original_price: 2200,
                        final_price: 2200,
                        quantity: 1,
                        original_line_price: 2200,
                        final_line_price: 2200,
                    }
                ]
            },
            routes: {
                account_addresses_url: '#'
            },
            customer: {
                addresses_count: 1,
                orders: [
                    {
                        customer_url: '#',
                        name: '#1001',
                        created_at: '2024-04-29 11:15:46 -0400',
                        financial_status_label: 'Paid',
                        fulfillment_status_label: 'Partial',
                        total_net_amount: '492.93'
                    }
                ],
                default_address: {
                    address1: 'Ha Noi',
                    address2: '',
                    city: 'Ha Noi',
                    country: 'Vietnam',

                },
                addresses: [
                    {
                        address1: 'Ha Noi',
                        address2: '',
                        city: 'Ha Noi',
                        country: 'Vietnam',
                    }
                ]
            },
            form: {
                "posted_successfully?": false,
                password_needed: true
            },
            order: {
                line_items: [
                    {
                        key: '123',
                        url: '#',
                        title: '2-Pack dots bibs taupe',
                        sku: '',
                        original_price: 22000,
                        final_price: 22000,
                        quantity: '1',
                        original_line_price: 22000,
                        final_line_price: 22000,
                    }
                ],
                line_items_subtotal_price: 22000,
                shipping_methods: [
                    {
                        title: 'Standard',
                        price: 0
                    }
                ],
                tax_lines: [
                    {
                        title: 'VAT 10.0%',
                        rate: 0,
                        price: 2200
                    }
                ],
                total_net_amount: 24200
            },
            page: {
                title: 'Page heading 1'
            },
            product: globalProduct,
            collection: globalCollection,
            collections: globalCollections,
            linklists: globalLinklists,
            blogs: globalBlogs,
            blog: globalBlog,
            article: globalArticle,
            recommendations: globalRecommendations,
            search: globalSearch
        });

        html = html.replaceAll('{{ sale_percent }}', '20%');

        const div = document.createElement('div');
        div.setAttribute('id', section.randomId);
        div.setAttribute('data-render', keyRender);
        div.classList.add('section')
        div.innerHTML = `<section class="shopify-section smi-section smi-${section.core === 'subtotal-1' ? 'smi-subtotal' : section.core}" id="shopify-section-${section.randomId}">${html}</section>`;

        //Chặn thẻ a
        const links = div.querySelectorAll('a:not(.smi-hook-lightbox)')
        links.forEach(link => {
            const role = link.getAttribute('role');
            if (role !== 'button') {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
            }
        })

        //Chặn submit form
        const forms = div.querySelectorAll('.smi-form, .smi-section form');
        forms.forEach(form => {
            form.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
            })
        })

        //Pagination click
        div.querySelectorAll('.smi-component-sticky-page-dot').forEach(el => {
            const btnNext = el.querySelector('[data-control="textNextSlide"]');
            const btnPrev = el.querySelector('[data-control="textPrevSlide"]');
            if (btnNext && btnPrev) {
                let swiperEl = el.closest('.smi-section__inner').querySelector(".smi-swiper-nested");
                if (!swiperEl) {
                    swiperEl = el.closest('.smi-section__inner').querySelector('.swiper')
                }
                btnNext.addEventListener('click', () => {
                    swiperEl.swiper.slideNext()
                })
                btnPrev.addEventListener('click', () => {
                    swiperEl.swiper.slidePrev()
                })
            }
        });

        let scripts = div.getElementsByTagName('script');
        let scriptCount = scripts.length;

        setTimeout(() => {
            for (let i = 0; i < scriptCount; i++) {
                try {
                executeAndRemoveScript(scripts[i]);
                } catch(e) {}
                
            }
        }, 100)

        if(section.core === 'password-page-1') {
            setTimeout(() => {
                excuteJs();
            }, 500);
        }

        return div;
        }catch(e) {
            console.log('ERROR', e);
        }
    }

    const excuteJs = () => {
        const buttonOpen = document.querySelector('.modal__toggle-open');
        if(buttonOpen) {
            buttonOpen.addEventListener('click', () => {
                const _parent = buttonOpen.closest('.password-header');
                const _modal = _parent.querySelector('.password-modal');
                if(_modal) {
                    setTimeout(() => {
                        _modal.setAttribute('open', true);
                    }, 100)                    
                }

                if(_parent) {
                    _parent.classList.add('open');
                }

            });
        }

        const buttonClose = document.querySelector('.modal__close-button');
        const bgClose = document.querySelector('.smi-background-pw');

        if(bgClose) {
            bgClose.addEventListener('click', () => {
                const _parent = bgClose.closest('.password-header');
                const _modal = _parent.querySelector('.password-modal');
                if(_modal) {
                    _modal.removeAttribute('open');
                }

                if(_parent) {
                    _parent.classList.remove('open');
                }
            });
        }

        if(buttonClose) {
            buttonClose.addEventListener('click', () => {
                const _parent = buttonClose.closest('.password-header');
                const _modal = _parent.querySelector('.password-modal');
                if(_modal) {
                    _modal.removeAttribute('open');
                }

                if(_parent) {
                    _parent.classList.remove('open');
                }
            });
        }
    }

    let keyssFo = '';

    //Get all actions
    useEffect(() => {
        const handler = async (e) => {
            if (e.data?.type) {
                if (e.data.type.includes('change-height')) {
                    let temp = e.data.data
                    const iframe = document.querySelector(`.section#${temp.id} iframe`)
                    if (iframe) {
                        iframe.style.height = temp.height + 'px'
                    }
                }

                if (e.data.type.includes('iframe-clicked')) {
                    if (previewType === '') {
                        let temp = e.data.data
                        const sections = document.querySelectorAll('.section.active')
                        sections.forEach(section => {
                            section.classList.remove('active')
                        })

                        const currentSection = document.getElementById(temp.id)

                        if (currentSection) {
                            currentSection.classList.add('active')
                        }

                        window.parent.postMessage({
                            type: ['select'],
                            data: {
                                currentId: temp.id
                            }
                        }, '*');
                    }
                }
            }

            if (e.origin === origin) {
                /**
                 * Theme builder
                 * Khi thay đổi template thì kiểm tra xem
                 * Nếu là template password thì ẩn header/footer group
                 */
                if (e.data.type.includes('change-template')) {
                    const template = e.data.handle;
                    const keyRender = e.data.keyRender;

                    const headerGroupEl = document.getElementById('header-group');
                    const footerGroupEl = document.getElementById('footer-group');
                    const globalGroupEl = document.getElementById('global-group');

                    if (template === 'password') {
                        if (headerGroupEl) { headerGroupEl.classList.add('d-none'); }
                        if (footerGroupEl) { footerGroupEl.classList.add('d-none'); }
                        if (globalGroupEl) { globalGroupEl.classList.add('d-none'); }
                    } else {
                        if (headerGroupEl) { headerGroupEl.classList.remove('d-none'); }
                        if (footerGroupEl) { footerGroupEl.classList.remove('d-none'); }
                        if (globalGroupEl) { globalGroupEl.classList.remove('d-none'); }
                    }
                }

                /**
                 * Theme builder
                 * Ẩn header/footer và empty action khi mới vào
                 */
                if (e.data.type.includes('theme-init')) {
                    setHideHeader(true);
                    setEmptyAction(false);
                }

                /**
                 * All
                 * Remove loading khi iframe loaded
                 */
                if (e.data.type.includes('remove-loading')) {
                    const wrapper = document.querySelector('._wrapper')
                    wrapper && wrapper.classList.remove('loading')
                }

                /**
                 * All
                 * Move element when dragging
                 */
                if (e.data.type.includes('move')) {
                    let temp = e.data.data
                    let appendEl = document.getElementById(temp.belowId)
                    let currentEl = document.getElementById(temp.currentId)

                    if (currentEl) {
                        currentEl.classList.add('move')
                    }

                    appendEl.parentNode.insertBefore(currentEl, appendEl.nextSibling);

                    if (appendEl) {
                        currentEl.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                        });
                    }
                }

                /**
                 * All
                 * Khi chọn item section bên sidebar thì preview bên này active
                 */
                if (e.data.type.includes('select')) {
                    const els = document.querySelectorAll('.section.active, .section.move')
                    els.forEach(it => {
                        it.classList.remove('active')
                        it.classList.remove('move')
                    })
                    const el = document.getElementById(e.data.data.currentId)
                    if (el) {
                        el.classList.add('active')
                        setTimeout(() => {
                            el.scrollIntoView({
                                behavior: "smooth"
                            });
                        }, 10)
                    }
                }

                /**
                 * All
                 * Move end
                 */
                if (e.data.type.includes('moveEnd')) {
                    const els = document.querySelector('.section.move')
                    if(els) {
                        els.classList.remove('move')

                        setTimeout(() => {
                            els.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                            });
                        }, 250)
                    }
                }

                //Move start
                if (e.data.type.includes('moveStart')) {
                    const elsActive = document.querySelector('.section.active')
                    if (elsActive) {
                        elsActive.classList.remove('active')
                    }

                    const el = document.getElementById(e.data.data.currentId)
                    if(el) {
                        el.classList.add('move')
                        el.classList.add('active')
                    }
                }

                //Delete
                if (e.data.type.includes('delete')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.remove()
                    }

                    const wrapper = document.querySelector('.builder-preview')
                    setEmpty((wrapper.childNodes.length <= 1))
                }

                //Delete all
                if (e.data.type.includes('delete-all')) {
                    const group = e.data?.group
                    if (group === 'template') {
                        const sections = document.querySelectorAll('.builder-preview #template-group .section')
                        sections.forEach(it => {
                            it.remove();
                        })
                    } else {
                        const sections = document.querySelectorAll('.builder-preview .section')
                        sections.forEach(it => {
                            it.remove();
                        })
                        setEmpty(true)
                    }
                }

                //Hide
                if (e.data.type.includes('hide')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.classList.add('hidden')
                    }
                }

                //Show
                if (e.data.type.includes('show')) {
                    let temp = e.data.data
                    let currentEl = document.getElementById(temp.currentId)
                    if (currentEl) {
                        currentEl.classList.remove('hidden')
                    }
                }

                if (e.data.type.includes('magic')) {
                    let temp = e.data.data
                    const { section, currentSection, scroll } = temp

                    try {
                        const div = await getInsertDivData(section);
                        const currentEl = document.getElementById(currentSection.randomId)
                        currentEl.style.display = 'none'
                        currentEl.parentNode.insertBefore(div, currentEl.nextSibling);
                        currentEl.remove()

                        if (!!scroll) {
                            div.scrollIntoView({
                                behavior: "instant"
                            });
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    window.parent.postMessage({
                        type: ['magic-end']
                    }, '*');

                    clickToPreview()
                }

                if (e.data.type.includes('magic-all')) {
                    let temp = e.data.data
                    const { sections } = temp

                    try {
                        if (sections.length) {
                            for (let i = 0; i < sections.length; i++) {
                                const section = sections[i];

                                const currentSection = section.currentSection;
                                const div = await getInsertDivData(section);

                                const currentEl = document.getElementById(currentSection.randomId);
                                currentEl.style.display = 'none';
                                currentEl.parentNode.insertBefore(div, currentEl.nextSibling);
                                currentEl.remove();
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    window.parent.postMessage({
                        type: ['magic-all-end']
                    }, '*');

                    clickToPreview()
                }

                //Add
                if (e.data.type.includes('add')) {
                    let temp = e.data.data
                    try {
                        setEmpty(false)
                        const { section, currentSection } = temp
                        const group = section.group;
                        const parent = document.querySelector('.builder-preview')

                        //Data                      
                        const div = await getInsertDivData(section);

                        if (!!currentSection) {
                            const currentEl = document.getElementById(currentSection.randomId);
                            currentEl.insertAdjacentElement('afterend', div);
                            currentEl.remove()
                        } else {
                            if (!!group) {
                                appendDivToGroup(group, div)
                            } else {
                                parent.appendChild(div);
                            }
                        }

                        setTimeout(() => {
                            div.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                            });
                        }, 5)
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    clickToPreview()
                }

                if (e.data.type.includes('add-many')) {
                    setEmpty(false);

                    setHideHeader(true);

                    const { type, sections, groups, isDelete, keyRender } = e.data.data

           
                    setNewKey(keyRender);
                    keyssFo = keyRender;

                    const keyss = { keyRender };

                    let parent = null
                    if (!!groups) {
                        parent = document.querySelector('.builder-preview #template-group')
                        if (!!isDelete) {

                        }
                    } else {
                        parent = document.querySelector('.builder-preview')
                    }

                    if (!!isDelete) {
                        if (!!groups) {
                            const sections = document.querySelectorAll('.builder-preview #template-group .section')
                            sections.forEach(it => {
                                it.remove();
                            })
                        } else {
                            const sections = document.querySelectorAll('.builder-preview .section')
                            sections.forEach(it => {
                                it.remove();
                            })
                            setEmpty(true)
                        }
                    }

                    try {
                        if (type === 'section') {
                            if (!!groups) {
                                for (let j = 0; j < groups.length; j++) {
                                    const _group = groups[j];
                                    let currentId = '';
                                    for (let i = 0; i < sections?.[_group].length; i++) {
                                        const section = sections?.[_group][i];
                                      
                                        const isTemplate = section?.template ? true : false;
                                        
                                        const div = await getInsertDivData(section, isTemplate, keyRender);
                                        
                                        if (div) {
                                            const divRender = div.getAttribute('data-render');
                                            if (_group === 'template') {
                                                if ((keyssFo == divRender) || !keyssFo) {
                                                    appendDivToGroup(_group, div);
                                                }
                                            } else {
                                                appendDivToGroup(_group, div);
                                            }
                                        }
                                    }
                                }
                            } else {
                                for (let i = 0; i < sections.length; i++) {
                                    const section = sections[i];
                                    const isTemplate = section?.template ? true : false;
                                    const div = await getInsertDivData(section, isTemplate);
                                    parent.appendChild(div);
                                }
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    setTimeout(() => {
                        clickToPreview()
                    }, 300)
                }

                //Add many
                if (e.data.type.includes('add-many1')) {
                    //setLoading(1)
                    setEmpty(false)

                    const { type, sections, previewStyle = 'classic-1', previewUrl, group } = e.data.data

                    let parent = null
                    if (group === 'template') {
                        parent = document.querySelector('.builder-preview #template-group')
                    } else {
                        parent = document.querySelector('.builder-preview')
                    }

                    // const wrapper = document.querySelector('._wrapper')
                    // wrapper.classList.add('loading')

                    try {
                        let blobStyle = ''
                        if (previewStyle.includes('.smi-section')) {
                            blobStyle = previewStyle
                        } else {
                            blobStyle = await getStyles(previewStyle)
                        }

                        if (type === 'section') {
                            for (let i = 0; i < sections.length; i++) {
                                const section = sections[i];
                                const id = section.randomId
                                const core = section.core
                                const preset = parseInt(section.preset) - 1
                                const previewUrl = getSectionPreviewUrl(core, section.preset)
                                const html = await getSectionHTML(previewUrl, preset, id)
                                const blobUrl = await getBlobUrl(html, blobStyle, false)
                                const div = createDivFrame(blobUrl, id)
                                parent.appendChild(div);
                            }
                        } else {
                            const sectionsDOM = await getPreviewDOM(previewUrl)
                            for (let i = 0; i < sectionsDOM.length; i++) {
                                const id = sections[i].randomId
                                const section = sectionsDOM[i];
                                const divTemp = document.createElement("div");
                                divTemp.id = id
                                divTemp.appendChild(section.parentNode)
                                const blobUrl = await getBlobUrl(divTemp.outerHTML, blobStyle, false)
                                const div = createDivFrame(blobUrl, id)
                                parent.appendChild(div);
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    setTimeout(() => {
                        //setLoading(0)
                        clickToPreview()
                    }, 300)
                }

                //Add many
                if (e.data.type.includes('add-quickbuild')) {
                    //setLoading(1)
                    setEmpty(false)

                    const { sections, previewStyle } = e.data.data

                    const parent = document.querySelector('.builder-preview')
                    // const wrapper = document.querySelector('._wrapper')
                    // wrapper.classList.add('loading')

                    try {
                        let blobStyle = ''
                        if (previewStyle.includes('.smi-section')) {
                            blobStyle = previewStyle
                        } else {
                            blobStyle = await getStyles(previewStyle)
                        }

                        for (let i = 0; i < sections.length; i++) {
                            const { randomId, core, preset, template, position } = sections[i]
                            let previewUrl = ''
                            let previewPreset = 0

                            if (!template) {
                                previewUrl = getSectionPreviewUrl(core, preset)
                                previewPreset = parseInt(preset) - 1
                            } else {
                                const response = await fetch(`${origin}/libraries/templates/${template}/index.json`);
                                const templateData = await response.json();
                                previewUrl = templateData.previewUrl
                                previewPreset = parseInt(position)
                            }
                            const html = await getSectionHTML(previewUrl, previewPreset, randomId)
                            const blobUrl = await getBlobUrl(html, blobStyle, false)
                            const div = createDivFrame(blobUrl, randomId)
                            parent.appendChild(div);
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    setTimeout(() => {
                        //setLoading(0)
                        clickToPreview()
                    }, 300)
                }

                //Preview
                if (e.data.type.includes('previews')) {
                    setHideHeader(true)
                    //setLoading(1)
                    setEmpty(false)

                    const parent = document.querySelector('.builder-preview')
                    let temp = e.data.data

                    const { previewType, previewUrl, previewStyle, previewPreset } = temp

                    setPreviewType(previewType)

                    // const wrapper = document.querySelector('._wrapper')
                    // wrapper.classList.add('loading')
                    try {
                        const sections = await getPreviewDOM(previewUrl)
                        if (previewType === 'section') {
                            let currentSection = sections[previewPreset - 1]
                            if (!currentSection) {
                                currentSection = sections[0]
                            }
                            const id = 'preview-section-' + previewPreset

                            var el = document.createElement("div");
                            el.id = id
                            el.appendChild(currentSection.parentNode)

                            const blobUrl = await getBlobUrl(el.outerHTML, previewStyle)
                            const div = createDivFrame(blobUrl, id)
                            div.classList.add('section-preview')
                            parent.appendChild(div);
                        } else {
                            let blobStyle = await getStyles(previewStyle)
                            for (let i = 0; i < sections.length; i++) {
                                const id = 'preview-section-' + i
                                const section = sections[i];

                                var divTemp = document.createElement("div");
                                divTemp.id = id
                                divTemp.appendChild(section.parentNode)

                                const blobUrl = await getBlobUrl(divTemp.outerHTML, blobStyle, false)
                                const div = createDivFrame(blobUrl, id)
                                div.classList.add('section-preview')
                                parent.appendChild(div);
                            }
                        }
                    } catch (error) {
                        console.error("Error:", error);
                    }

                    setTimeout(() => {
                        //setLoading(0)
                    }, 300)
                }

                //Change style
                if (e.data.type.includes('change-style')) {
                    let temp = e.data.data

                    const styleOld = document.querySelector('#preview-style')
                    if (styleOld) {
                        styleOld.remove();
                    }

                    const head = document.head || document.getElementsByTagName('head')[0]
                    const styleTag = document.createElement('style');
                    styleTag.id = 'preview-style'
                    head.appendChild(styleTag);
                    styleTag.type = 'text/css';
                    if (styleTag.styleSheet) {
                        // This is required for IE8 and below.
                        styleTag.styleSheet.cssText = temp.style;
                    } else {
                        let _temp = temp.style;
                        styleTag.appendChild(document.createTextNode(_temp));
                    }
                }

                //Change font
                if (e.data.type.includes('change-font')) {
                    let temp = e.data.data

                    const { style, remove } = temp;

                    const styleOld = document.querySelector('#preview-font')
                    if (styleOld) {
                        styleOld.remove();
                    }

                    if (!remove && style !== '') {
                        const head = document.head || document.getElementsByTagName('head')[0]
                        const styleTag = document.createElement('style');
                        styleTag.id = 'preview-font'
                        head.appendChild(styleTag);
                        styleTag.type = 'text/css';
                        if (styleTag.styleSheet) {
                            styleTag.styleSheet.cssText = style;
                        } else {
                            styleTag.appendChild(document.createTextNode(style));
                        }
                    } else {
                        console.log('remove-font');
                    }
                }

                //Toggle header/footer
                if (e.data.type.includes('hide-header')) {
                    //console.log('hide-header');
                    let temp = e.data.data
                    setHideHeader(temp)
                }
            }
        }

        window.addEventListener('message', handler)
        return () => window.removeEventListener('message', handler)
    }, [])

    //Click to preview to active on sidebar items
    const clickToPreview = () => {
        const elements = document.querySelectorAll('.section')
        if (elements.length) {
            elements.forEach(element => {
                element.addEventListener('click', () => {
                    const activeElement = document.querySelector('.section.active, .section.move')
                    if (activeElement) {
                        activeElement.classList.remove('active')
                        activeElement.classList.remove('move')
                    }
                    element.classList.add('active')
                    const id = element.getAttribute('id')
                    window.parent.postMessage({
                        type: ['select'],
                        data: {
                            currentId: id
                        }
                    }, '*');
                })

                element.addEventListener('mouseleave', () => {
                    if (element.classList.contains('active')) {
                        element.classList.remove('active')
                        window.parent.postMessage({
                            type: ['select'],
                            data: {
                                currentId: ''
                            }
                        }, '*');
                    }
                })
            })
        }
    }

    useEffect(() => {
        clickToPreview()
    }, [])

    return (
        <div className={`_wrapper preview-${previewType} ${!!loading ? 'loading' : ''} ${loading === 2 ? 'loading--opacity' : ''}`} >
            <Header status={hideHeader} />
            <Main status={empty} emptyAction={emptyAction} />
            <Footer status={hideHeader} />
        </div>
    )
}

export default App;
