import Empty from "./Empty"

function Main({status, emptyAction}) {
    return (
        <div className="builder-preview">
            <div id="top-preview">
                <Empty status={status} action={emptyAction}/>
            </div>
            <div id="header-group" className="preview-group"><div id="header-top-preview"></div></div>
            <div id="template-group" className="preview-group"><div id="template-top-preview"></div></div>
            <div id="footer-group" className="preview-group"><div id="footer-top-preview"></div></div>
            <div id="global-group" className="preview-group"><div id="global-top-preview"></div></div>
        </div>
    )
}

export default Main