const textAI = {
    "heading": {
        "heading-1": {
            'heading': "Why Choose Us?",
            'subheading': "Why Choose Us?",
            'description': "Why Choose Us?",
            'core': ['all']
        },
        "heading-2": {
            'text': "Related products?",
            'core': ['related-product-1', 'product-detail']
        },
        "heading-3": {
            "heading": "Shop By Categories ",
            "subheading": "THE ESSENTIALS",
            "description": "Explore our most exquisite collections and make your favorite items yours.",
            "core": ['collection-list-2', 'image-with-text-1', 'featured-collection-1']
        },
        "heading-4": {
            "heading": "Find Your Collection",
            "subheading": "FAVORITE EXT",
            "description": "Find collection you want to shine on the Shopify store featured products.",
            "core": ['collection-list-2', 'image-with-text-1', 'featured-collection-1', 'blog-posts-1']
        },
        "heading-5": {
            "heading": "Best Seller Product",
            "subheading": "ALL THE WORLD",
            "description": "Top #1 of the world for collection and beauty store with experience beast.",
            "core": ['collection-list-2', 'featured-collection-1', 'blog-posts-1']
        }
    },
    "product": {
        "default": [
            { "name": 'Default product 1', "subheading": 'Default 1', "image": "image_1.png", "image_hover": "image_2.png" },
            { "name": 'Default product 2', "subheading": 'Default 2', "image": "image_2.png", "image_hover": "image_3.png" },
            { "name": 'Default product 3', "subheading": 'Default 3', "image": "image_3.png", "image_hover": "image_4.png" },
            { "name": 'Default product 4', "subheading": 'Default 4', "image": "image_4.png", "image_hover": "image_5.png" },
            { "name": 'Default product 5', "subheading": 'Default 5', "image": "image_5.png", "image_hover": "image_6.png" },
            { "name": 'Default product 6', "subheading": 'Default 6', "image": "image_6.png", "image_hover": "image_7.png" },
            { "name": 'Default product 7', "subheading": 'Default 7', "image": "image_7.png", "image_hover": "image_8.png" },
            { "name": 'Default product 8', "subheading": 'Default 8', "image": "image_8.png", "image_hover": "image_9.png" },
            { "name": 'Default product 9', "subheading": 'Default 9', "image": "image_9.png", "image_hover": "image_10.png" },
            { "name": 'Default product 10', "subheading": 'Default 10', "image": "image_10.png", "image_hover": "image_1.png" }
        ],
        "arts-and-crafts": [
            { "name": 'Art product 1', "subheading": 'Art 11', "image": "image_1.png", "image_hover": "image_2.png" },
            { "name": 'Art product 2', "subheading": 'Art 22', "image": "image_2.png", "image_hover": "image_3.png" },
            { "name": 'Art product 3', "subheading": 'Art 33', "image": "image_3.png", "image_hover": "image_4.png" },
            { "name": 'Art product 4', "subheading": 'Art 44', "image": "image_4.png", "image_hover": "image_5.png" },
            { "name": 'Art product 5', "subheading": 'Art 55', "image": "image_5.png", "image_hover": "image_6.png" },
            { "name": 'Art product 6', "subheading": 'Art 66', "image": "image_6.png", "image_hover": "image_7.png" },
            { "name": 'Art product 7', "subheading": 'Art 77', "image": "image_7.png", "image_hover": "image_8.png" },
            { "name": 'Art product 8', "subheading": 'Art 88', "image": "image_8.png", "image_hover": "image_9.png" },
            { "name": 'Art product 9', "subheading": 'Art 99', "image": "image_9.png", "image_hover": "image_10.png" },
            { "name": 'Art product 10', "subheading": 'Art 1010', "image": "image_10.png", "image_hover": "image_1.png" }
        ]
    },
    "collection": {
        "default": [
            { "name": 'Mini Dresses', "subheading": 'Mini 1', "image": "image_1.png" },
            { "name": 'Set Dresses', "subheading": 'Mini 2', "image": "image_2.png" },
            { "name": 'Dresss', "subheading": 'Mini 3', "image": "image_3.png" },
            { "name": 'Dress Shirts', "subheading": 'Mini 4', "image": "image_4.png" },
            { "name": 'Red Clothing', "subheading": 'Mini 5', "image": "image_5.png" },
            { "name": 'Suilt man', "subheading": 'Mini 6', "image": "image_6.png" },
            { "name": 'Baby clothing', "subheading": 'Mini 7', "image": "image_7.png" },
            { "name": 'Beauty shoes', "subheading": 'Mini 8', "image": "image_8.png" },
            { "name": 'Bedje woman', "subheading": 'Mini 9', "image": "image_9.png" },
            { "name": 'Jewie gold', "subheading": 'Mini 10', "image": "image_10.png" }
        ],
        "arts-and-crafts": [
            { "name": 'Art crafts 1', "subheading": 'Mini 11', "image": "image_1.png" },
            { "name": 'Art crafts 2', "subheading": 'Mini 22', "image": "image_2.png" },
            { "name": 'Art crafts 3', "subheading": 'Mini 33', "image": "image_3.png" },
            { "name": 'Art crafts 4', "subheading": 'Mini 44', "image": "image_4.png" },
            { "name": 'Art crafts 5', "subheading": 'Mini 55', "image": "image_5.png" },
            { "name": 'Art crafts 6', "subheading": 'Mini 66', "image": "image_6.png" },
            { "name": 'Art crafts 7', "subheading": 'Mini 77', "image": "image_7.png" },
            { "name": 'Art crafts 8', "subheading": 'Mini 88', "image": "image_8.png" },
            { "name": 'Art crafts 9', "subheading": 'Mini 99', "image": "image_9.png" },
            { "name": 'Art crafts 10', "subheading": 'Mini 1010', "image": "image_10.png" }
        ]
    },
    "blog": {
        "default": [
            { "name": 'Default blog 1', "description": 'Default 1', "image": "image_1.png" },
            { "name": 'Default blog 2', "description": 'Default 2', "image": "image_2.png" },
            { "name": 'Default blog 3', "description": 'Default 3', "image": "image_3.png" },
            { "name": 'Default blog 4', "description": 'Default 4', "image": "image_4.png" },
            { "name": 'Default blog 5', "description": 'Default 5', "image": "image_5.png" },
            { "name": 'Default blog 6', "description": 'Default 6', "image": "image_6.png" },
            { "name": 'Default blog 7', "description": 'Default 7', "image": "image_7.png" },
            { "name": 'Default blog 8', "description": 'Default 8', "image": "image_8.png" },
            { "name": 'Default blog 9', "description": 'Default 9', "image": "image_9.png" },
            { "name": 'Default blog 10', "description": 'Default 10', "image": "image_10.png" }
        ],
        "arts-and-crafts": [
            { "name": 'Art blog 1', "description": 'Art 11', "image": "image_1.png" },
            { "name": 'Art blog 2', "description": 'Art 22', "image": "image_2.png" },
            { "name": 'Art blog 3', "description": 'Art 33', "image": "image_3.png" },
            { "name": 'Art blog 4', "description": 'Art 44', "image": "image_4.png" },
            { "name": 'Art blog 5', "description": 'Art 55', "image": "image_5.png" },
            { "name": 'Art blog 6', "description": 'Art 66', "image": "image_6.png" },
            { "name": 'Art blog 7', "description": 'Art 77', "image": "image_7.png" },
            { "name": 'Art blog 8', "description": 'Art 88', "image": "image_8.png" },
            { "name": 'Art blog 9', "description": 'Art 99', "image": "image_9.png" },
            { "name": 'Art blog 10', "description": 'Art 1010', "image": "image_10.png" }
        ]
    }
}

const classesAI = [
    {
        "cores": ['collection-list-2'],
        "item": ".smi-card-collection"
    },
    {
        "cores": ['featured-collection-1'],
        "item": ".smi-product-card"
    },
    {
        "cores": ['blog-posts-1'],
        "item": ".smi-component-settings-blog-item"
    }
]

const previewSectionsURL = {
    'announcement-bar-1': 'pages/annoucement-bar-1',
    'announcement-bar-2': 'pages/annoucement-bar-2',
    'blog-posts-1': 'pages/blog-post-1',
    'blog-posts-2': 'pages/blog-post-2',
    'collapsible-content-1': 'pages/collapsible-content-1',
    'collapsible-content-2': 'pages/collapsible-content-2',
    'collapsible-content-3': 'pages/collapsible-content-3',
    'collapsible-content-4': 'pages/collapsible-content-4',
    'collapsible-content-5': 'pages/collapsible-content-5',
    'collection-list-1': 'pages/collection-list-1',
    'collection-list-2': 'pages/collection-list-2',
    'collection-list-3': 'pages/collection-list-3',
    'collection-list-4': 'pages/collection-list-4',
    'collection-list-5': 'pages/collection-list-5',
    'collection-list-6': 'pages/collection-list-6',
    'collection-list-7': 'pages/collection-list-7',
    'collection-list-8': 'pages/collection-list-8',
    'collection-list-9': 'pages/collection-list-9',
    'collection-list-10': 'pages/collection-list-10',
    'collection-list-11': 'pages/collection-list-11',
    'collection-list-12': 'pages/collection-list-12',
    'collection-page-1': 'https://app.smind.io/libraries/previews/sections/collection-page-1.html',
    'comparison-table-1': 'pages/comparison-table-1',
    'contact-1': 'pages/contact-1',
    'countdown-1': 'pages/countdown-timer-1',
    'countdown-2': 'pages/countdown-timer-2',
    'countdown-3': 'pages/countdown-timer-3',
    'countdown-4': 'pages/countdown-4',
    'countdown-5': 'pages/countdown-5',
    'countdown-6': 'pages/countdown-6',
    'countdown-7': 'pages/countdown-7',
    'email-signup-1': 'pages/email-signup-1',
    'email-signup-2': 'pages/email-sign-up-2',
    'email-signup-3': 'pages/email-signup-3',
    'email-signup-4': 'pages/email-signup-4',
    'featured-collection-1': 'pages/feature-collection-1',
    'featured-collection-2': 'pages/feature-collection-2',
    'featured-collection-3': 'pages/feature-collection-3',
    'featured-collection-4': 'pages/feature-collection-4',
    'featured-collection-5': 'pages/feature-collection-5',
    'featured-collection-6': 'pages/feature-collection-6',

    'featured-product-1-1': 'pages/feature-product-1?variant=47832215126209',
    'featured-product-1-2': 'pages/smind-demo?variant=48233484550337&view=pre-featured-product-1-2',
    'featured-product-1-3': 'pages/smind-demo?variant=48218171277505&view=pre-featured-product-1-3',
    'featured-product-1-4': 'pages/smind-demo?view=pre-featured-product-1-4',
    'featured-product-1-5': 'pages/smind-demo?view=pre-featured-product-1-5',
    'featured-product-1-6': 'pages/smind-demo?variant=48219359641793&view=pre-featured-product-1-6',

    'footer-1': 'pages/footer-1',
    'header-1': 'pages/header-1',
    'icon-with-text-1': 'pages/icon-with-text-1',
    'icon-with-text-2': 'pages/icon-with-text-2',
    'image-comparison-1': 'pages/image-comparison-1',
    'image-comparison-2': 'pages/image-comparison-2',
    'image-hero-1': 'pages/image-hero-1',
    'image-with-text-1': 'pages/image-with-text-1',
    'image-with-text-2': 'pages/image-with-text-2',
    'image-with-text-3': 'pages/image-with-text-3',
    'image-with-text-4': 'pages/image-with-text-4',
    'logo-list-1': 'pages/logo-list-1',
    'logo-list-2': 'pages/logo-list-2',
    'logo-list-3': 'pages/logo-list-3',
    'logo-list-4': 'pages/logo-list-4',
    'map-1': 'pages/map-1',
    'map-2': 'pages/map-2',
    'page-heading-1': 'pages/page-heading-1',
    'product-bundles-1': 'products/the-ragged-priest-goliath-unisex-jeans',

    'product-detail-1-1': 'products/we-the-free-cuddle-v-neck-sweater?variant=47832197955777',
    'product-detail-1-2': 'products/all-that-sweatshirt?view=preset-product-detail-1-2&variant=47832201494721&ose=false',
    'product-detail-1-3': 'products/copy-of-kamali-boot-pant-suit?view=preset-product-detail-1-3&variant=47832160993473&ose=false',
    'product-detail-1-4': 'products/copy-of-fenty-skin-travel?view=preset-product-detail-1-4',
    'product-detail-1-5': 'products/true-wireless-headphones?view=preset-product-detail-1-5&variant=47832128422081',
    'product-detail-1-6': 'products/rolo-chain-charm-necklace?view=preset-product-detail-1-6&variant=47832129765569',
    'product-detail-1-7': 'products/lucas-tub-chair?view=preset-product-detail-1-7&variant=47832126554305',
    'product-detail-1-8': 'products/fine-grooves-vase?view=preset-product-detail-1-8&variant=47832113184961',
    'product-detail-1-9': 'products/duramo-running-shoes?view=preset-product-detail-1-9&variant=47832111382721',
    'product-detail-1-10': 'products/energy-drink-hawaiian?view=pre-product-detail-1-10',
    'product-detail-1-11': 'products/hardy-houseplants?view=pre-product-detail-1-11',
    'product-detail-1-12': 'products/fraxinus-dashe?view=pre-product-detail-1-12&variant=',
    'product-detail-1-13': 'products/little-women?view=pre-product-detail-1-13',

    'product-info-tabs-1': 'products/bodhi-fisher-sneakers?view=product-infor-tabs-1',

    'blog-page-1-1': 'https://app.smind.io/libraries/previews/sections/blog-page-1-1.html',
    'blog-page-1-2': 'https://app.smind.io/libraries/previews/sections/blog-page-1-2.html',
    'blog-page-1-3': 'https://app.smind.io/libraries/previews/sections/blog-page-1-3.html',
    'blog-page-1-4': 'https://app.smind.io/libraries/previews/sections/blog-page-1-4.html',

    'blog-post-article-1-1': 'https://app.smind.io/libraries/previews/sections/blog-post-article-1-1.html',
    'blog-post-article-1-2': 'https://app.smind.io/libraries/previews/sections/blog-post-article-1-2.html',
    'blog-post-article-1-3': 'https://app.smind.io/libraries/previews/sections/blog-post-article-1-3.html',

    'coming-soon-1': 'https://app.smind.io/libraries/previews/sections/coming-soon-1-1.html',
    'page-404-1': 'https://app.smind.io/libraries/previews/sections/404-page-1-1.html',

    'account-1': 'https://app.smind.io/libraries/previews/sections/account-1-1.html',
    'addresses-1': 'https://app.smind.io/libraries/previews/sections/addresses-1-1.html',
    'login-1': 'https://app.smind.io/libraries/previews/sections/login-1-1.html',
    'order-1': 'https://app.smind.io/libraries/previews/sections/order-1-1.html',
    'registration-1': 'https://app.smind.io/libraries/previews/sections/registration-1-1.html',
    'subtotal-1': 'https://app.smind.io/libraries/previews/sections/subtotal-1-1.html',
    'items-cart-1': 'https://app.smind.io/libraries/previews/sections/items-cart-1-1.html',

    'related-products-1': 'products/we-the-free-camden-sweatshirt',
    'review-1': 'pages/review-1',
    'review-2': 'pages/review-2',
    'review-3': 'pages/review-3',
    'review-4': 'pages/review-4',
    'rich-content-1': 'pages/rich-content-1',
    'rich-text-1': 'pages/rich-text-1',
    'rich-text-2': 'pages/rich-text-2',
    'scrolling-text-1': 'pages/scrolling-text-1',
    'shop-the-look-1': 'pages/shop-the-look-1',
    'slideshow-1': 'pages/slideshow-1',
    'slideshow-2': 'pages/slideshow-2',
    'testimonial-1': 'pages/testimonial-1',
    'testimonial-2': 'pages/testimonial-2',
    'testimonial-3': 'pages/testimonial-3',
    'timeline-1': 'pages/timeline-1',
    'timeline-2': 'pages/timeline-2',
    'top-bar-1': 'pages/top-bar-1',
    'top-bar-2': 'pages/top-bar-2',
    'video-1': 'pages/video-1',
    'video-2': 'pages/video-2',
    'video-3': 'pages/video-3',
    'video-hero-1': 'pages/video-hero-1'
}

const localeJson = {
    "general": {
        "view_all": "View all",
        "load_more": "Load more",
        "verified_buyer": "Verified buyer",
        "show_more": "Show more",
        "search": "Search",
        "recent_searches": "Recent searches",
        "popular_searches": "Popular searches",
        "popular_items": "Popular items",
        "suggestions": "Suggestions",
        "products": "Products",
        "pages": "Pages",
        "search_for": "Search for “{{ terms }}”",
        "cart_is_empty": "Your cart is empty",
        "continue_shopping": "Continue shopping",
        "cart": "Cart",
        "estimated_total": "Estimated total",
        "checkout": "Check out",
        "view_cart": "View cart",
        "view_product": "View product",
        "on": "On",
        "by": "By",
        "view_more": "View more",
        "home": "Home"
    },
    "collection": {
        "clear_all": "Clear all",
        "to": "To",
        "sort_by": "Sort by:",
        "product_count": {
            "one": "{{ product_count }} product found",
            "other": "{{ product_count }} products found"
        },
        "product": {
            "one": "{{ product_count }} product",
            "other": "{{ product_count }} products"
        },
        "load_more": "Load more"
    },
    "search": {
        "title": "Search results",
        "search": "Search",
        "results_count": {
            "one": "Found {{ count }} result for \"{{ terms }}\"",
            "other": "Found {{ count }} results for \"{{ terms }}\""
        },
        "sort_by": "Sort by:",
        "no_results": "No results could be found. Please try again with a different query.",
        "blog": "Blog",
        "page": "Page"
    },
    "article": {
        "next_post": "Next",
        "previous_post": "Previous",
        "comments": {
            "one": "{{ count }} comment",
            "other": "{{ count }} comments"
        },
        "comment_form_title": "Leave a comment",
        "comment_form_description": "Your email address will not be published",
        "moderated": "Please note, comments need to be approved before they are published.",
        "name": "Name",
        "email": "Email",
        "message": "Message",
        "post": "Post comment",
        "error": "Error: An issue has occurred!",
        "success": "Your comment was posted successfully! Thank you!",
        "success_moderated": "Your comment was posted successfully. We will publish it in a little while, as our blog is moderated."
    },
    "form": {
        "text_button_subscribe": "Subscribe",
        "text_button_copy": "Copy",
        "text_copy_discount": "Copy Discount",
        "text_copied_discount": "Copied Discount",
        "text_button_send_message": "Send message",
        "label_name": "Name",
        "label_message": "Message",
        "placeholder_email": "Enter your email",
        "placeholder_email_password": "Your email",
        "field_required": "{{ field }} is required",
        "field_invalid": "{{ field }} is invalid",
        "form_error": "An error occurred, please check the information and resubmit.",
        "form_success": "Thanks for contacting us. We'll get back to you as soon as possible.",
        "form_email_success": "Thanks for subscribing",
        "label_email": "Email",
        "label_password": "Password",
        "log_in": "Login",
        "forgot_password": "Forgot your password?",
        "sign_in": "Sign in",
        "dont_have_account": "Don't have account?",
        "sign_up": "Sign up",
        "reset_your_password": "Reset your password",
        "reset_your_password_msg": "We will send you an email to reset your password",
        "submit": "Submit",
        "recover_password_success": "We've sent you an email with a link to update your password.",
        "recover_password_err": "No account found with that email.",
        "first_name": "First name",
        "last_name": "Last name",
        "create_account": "Create account"
    },
    "product": {
        "add_to_cart": "Add to cart",
        "buy_now": "Buy it now",
        "quantity": "Quantity",
        "product_type": "Product type",
        "vendor": "Vendor",
        "collections": "Collections",
        "tags": "Tags",
        "send_as_gift": "I want to send this as a gift",
        "max_textarea": "200 characters max",
        "send_on": "Send on(optional)",
        "in_cart": "in cart",
        "item_in_cart": "{{ cart_qty }} in cart",
        "all": "All",
        "one_item_al_added_cart": "item is already added to cart!",
        "many_items_al_added_cart": "items are already added to cart!",
        "one_item_in_cart": "item is in cart!",
        "many_items_in_cart": "items are in cart!",
        "cant_add_more": "You can't add more",
        "to_the_cart": "to the cart",
        "cart_added": "Cart added",
        "cart_failed": "Add cart failed",
        "sold_out": "Sold out",
        "sale": "Sale {{ sale_percent }}",
        "saleText": "Sale",
        "no_products": "No products",
        "from_price_html": "From {{ price }}",
        "unit_price": "Unit price",
        "unit_price_separator": "per",
        "select_options": "Select options",
        "total_price_product_bundle": "Total price",
        "add_item_to_cart_product_bundle_html": "Add (<span class='item-bunble-selected'><\/span>) items to cart",
        "add_all_item_to_cart_product_bundle_html": "Add all items to cart",
        "this_item": "This item"
    },
    "cart": {
        "taxes_and_shipping_policy_at_checkout_html": "Taxes, discounts and <a href=\"{{ link }}\">shipping<\/a> calculated at checkout",
        "taxes_included_but_shipping_at_checkout": "Tax included and shipping and discounts calculated at checkout",
        "taxes_included_and_shipping_policy_html": "Tax included. <a href=\"{{ link }}\">shipping<\/a> and discounts calculated at checkout.",
        "taxes_and_shipping_at_checkout": "Taxes, discounts and shipping calculated at checkout",
        "save": "Save",
        "cancel": "Cancel",
        "note_empty": "Note field is required",
        "note_error": "An error occurred, please check the information and resubmit",
        "product_lable": "Product",
        "quantity_label": "Quantity",
        "total_label": "Total",
        "title_cart_is_empty": "Your cart is empty",
        "subtitle_cart_is_empty": "Your cart is currently empty. Discover exciting products on our Shop page",
        "back_to_shopping": "Back to shopping",
        "empty": "Your cart is empty",
        "title_page": "Your cart",
        "continue_shopping": "Continue shopping",
        "empty_text": "Your cart is currently empty. Discover exciting products on our Shop page",
        "login": {
            "title": "Have an account?",
            "paragraph_html": "<a href=\"{{ link }}\" class=\"link underlined-link\">Log in<\/a> to check out faster."
        },
        "quantity": {
            "decrease": "Decrease quantity for",
            "increase": "Increase quantity for",
            "input": "Quantity for"
        },
        "product": {
            "price": {
                "regular_price": "Regular price",
                "sale_price": "Sale price",
                "unit_price": "Sale price"
            }
        },
        "add_order_note": "Add order note",
        "estimated_total": "Estimated total",
        "estimated_shipping": "Estimate shipping",
        "tax_discount_shipping": "Taxes, discounts and shipping calculated at checkout",
        "remove_title": "Remove {{ title }}",
        "shipping_estimator": {
            "title": "Estimate Shipping",
            "country": "Country",
            "choose_country": "Choose Country",
            "province": "Province",
            "choose_province": "Choose Province",
            "zip_code": "Enter a ZIP code",
            "estimate": "Estimate",
            "multiple_rates": "We found several shipping rates available for your address:",
            "one_rate": "There are several shipping rates for your address:",
            "no_rates": "There are no shipping rates for your address.",
            "errors": "There are some errors:",
            "free": "FREE"
        }
    },
    "account": {
        "dashboard": "Dashboard",
        "address": "Address",
        "wishlist": "Wishlist",
        "compare": "Compare",
        "log_out": "Log out",
        "order_history": "Order history",
        "order_title": "Order",
        "order_date": "Date",
        "order_payment_status": "Payment status",
        "order_fulfil": "Fulfillment status",
        "order_total": "Total",
        "account_address": "Account details",
        "default_address": "default address",
        "view_addresses": "VIEW ADDRESSES",
        "order_page": {
            "title": "Order {{ name }}",
            "date_html": "Placed on {{ date }}",
            "cancelled_html": "Order Cancelled on {{ date }}",
            "cancelled_reason": "Reason: {{ reason }}",
            "billing_address": "Billing Address",
            "payment_status": "Payment Status",
            "shipping_address": "Shipping Address",
            "fulfillment_status": "Fulfillment Status",
            "discount": "Discount",
            "shipping": "Shipping",
            "tax": "Tax",
            "product": "Product",
            "sku": "SKU",
            "price": "Price",
            "quantity": "Quantity",
            "total": "Total",
            "total_refunded": "Refunded",
            "fulfilled_at_html": "Fulfilled {{ date }}",
            "track_shipment": "Track shipment",
            "tracking_url": "Tracking link",
            "tracking_company": "Carrier",
            "tracking_number": "Tracking number",
            "subtotal": "Subtotal",
            "total_duties": "Duties"
        },
        "addresses": {
            "title1": "Your address",
            "title2": "Your addresses",
            "default": "default adderss",
            "add_new": "Add a new address",
            "edit_address": "Edit address",
            "first_name": "First name",
            "last_name": "Last name",
            "company": "Company",
            "address1": "Address 1",
            "address2": "Address 2",
            "city": "City",
            "country": "Country\/region",
            "province": "Province",
            "zip": "Postal\/ZIP code",
            "phone": "Phone",
            "set_default": "Set as default address",
            "add": "Add address",
            "update": "Update address",
            "cancel": "Cancel",
            "edit": "Edit",
            "delete": "Delete",
            "delete_confirm": "Are you sure you wish to delete this address?"
        },
        "password": {
            "error": "Error",
            "login_form_error": "Wrong password!",
            "login_password_button": "Enter using password",
            "close": "Close",
            "login_form_heading": "Enter store using password:",
            "login_form_password_placeholder": "Your password",
            "login_form_submit": "Enter",
            "admin_link_html": "<span>Are you the store owner?</span> <a href=\"\/admin\" class=\"link underlined-link\">Log in here<\/a>",
            "text_button_subscribe": "Notify me"
        }
    }
}

const menuJson = {
    'clothing': {
        menu_0: {
            title: "About",
            links: [
                {
                    url: '#',
                    title: "About us"
                },
                {
                    url: '#',
                    title: "Contact us"
                },
                {
                    url: '#',
                    title: "FAQs"
                }
            ]
        },
        menu_1: {
            title: "Shop",
            links: [
                {
                    url: '#',
                    title: "Shirts"
                },
                {
                    url: '#',
                    title: "Jacket"
                },
                {
                    url: '#',
                    title: "Dresses"
                },
                {
                    url: '#',
                    title: "Sweaters"
                }
            ]
        },
        menu_2: {
            title: "Support",
            links: [
                {
                    url: '#',
                    title: "Help center"
                },
                {
                    url: '#',
                    title: "Track your order"
                },
                {
                    url: '#',
                    title: "Shipping & returns"
                },
                {
                    url: '#',
                    title: "Affiliate"
                }
            ]
        },
        menu_header_1: {
            title: "Header 1",
            links: [
                {
                    url: '#',
                    title: "Home"
                },
                {
                    url: '#',
                    title: "Product",
                    links: [
                        {
                            url: '#',
                            title: "Dress",
                            links: [
                                {
                                    url: '#',
                                    title: "Dress"
                                },
                                {
                                    url: '#',
                                    title: "Jeans"
                                },
                                {
                                    url: '#',
                                    title: "Shirts"
                                }                                                   
                            ]
                        },
                        {
                            url: '#',
                            title: "Jeans",
                            links: [                               
                                {
                                    url: '#',
                                    title: "Shirts"
                                },
                                {
                                    url: '#',
                                    title: "Jackets"
                                },                               
                                {
                                    url: '#',
                                    title: "Swimsuits"
                                },
                                {
                                    url: '#',
                                    title: "Shirts"
                                },
                                {
                                    url: '#',
                                    title: "Bags"
                                }                        
                            ]
                        },
                        {
                            url: '#',
                            title: "Shirts",
                            links: [
                                {
                                    url: '#',
                                    title: "Jeans"
                                },
                                {
                                    url: '#',
                                    title: "Dress"
                                },
                                {
                                    url: '#',
                                    title: "Jeans"
                                },
                                {
                                    url: '#',
                                    title: "Shirts"
                                },
                                {
                                    url: '#',
                                    title: "Jackets"
                                }                                                     
                            ]
                        },
                        {
                            url: '#',
                            title: "Jackets",
                            links: [
                                {
                                    url: '#',
                                    title: "Dress"
                                },                                
                                {
                                    url: '#',
                                    title: "Suits"
                                },                               
                                {
                                    url: '#',
                                    title: "Bags"
                                }                        
                            ]
                        },
                        {
                            url: '#',
                            title: "Suits",
                            links: [
                                {
                                    url: '#',
                                    title: "Dress"
                                },
                                {
                                    url: '#',
                                    title: "Jeans"
                                }                                                      
                            ]
                        },
                        {
                            url: '#',
                            title: "Swimsuits",
                            links: [                              
                                {
                                    url: '#',
                                    title: "Jeans"
                                },
                                {
                                    url: '#',
                                    title: "Shirts"
                                },
                                {
                                    url: '#',
                                    title: "Jackets"
                                },
                                {
                                    url: '#',
                                    title: "Suits"
                                },
                                {
                                    url: '#',
                                    title: "Swimsuits"
                                },
                                {
                                    url: '#',
                                    title: "Bags"
                                }                        
                            ]
                        }                                             
                    ]
                },
                {
                    url: '#',
                    title: "Shop"
                },
                {
                    url: '#',
                    title: "Pages"
                }
            ]
        },
        'menu-2': {
            title: "Categories",
            links: [
                {
                    url: '#',
                    title: "Shirts"
                },
                {
                    url: '#',
                    title: "Jacket"
                },
                {
                    url: '#',
                    title: "Dresses"
                },
                {
                    url: '#',
                    title: "Sweaters"
                }  
            ]
        }
    }
}

const blogJson = {
    articles: [
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/BLOG-Emma-Hill-style.-Brown-checked-wool-jacket-and-scarf-black-skinny-trousers-black-leather-knee-high-heeled-boots-The-Row-Everyday-shoulder-bag.-Chic-autumn-fall-outfit.jpg?v=1711102129&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'Necessities: What you’ll find in our editors’ handbags',
            excerpt: 'Early starts and late nights are pretty much a given during the hectic schedule that is New Zealand Fashion Week. With its diverse array of shows, installations, lunches and after parties, the need for a reliable companion becomes paramount. Whether that takes the form of a trusty tote or an elegant clutch, a well-chosen handbag is an indispensable accessory during such weeks. Beyond its ability to provide the finishing touch to an already stellar ensemble, it also serves as a vessel for keeping an assortment of essentials on hand. As our team coordinates their itineraries and plans their outfits, one question remains: what does an FQ editor carry in their handbag during fashion week?',
            tags: ['Fashion', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/Emma-Hill-style.-Transitional-Spring-Autumn-outfit.-Off-white-cashmere-oversized-jumper-black-striaght-leg-slouchy-jeans-Birkenstock-Boston-clogs-The-Row-large-slouchy-Banan-bag.-Mini.jpg?v=1711102129&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'The knitwear collection you need to be investing in now',
            excerpt: 'Designed with timelessness and durability in mind, the collection champions a variety of fabrics and silhouettes, including the transeasonal Ultrafine Merino, and the soon-to-be-released Merino Cotton blend seen in crew and v-neck sweaters, collared jerseys, classic t-shirts and tanks. Moving into summer, there will also be several maxi and midi dress styles arriving in lightweight fabrications accompanied by an effortlessly-cool linen suit set that invites year round versatility. Ideal pieces to be worn alone or layered together, each style is crafted to encourage the wearer to pause and feel the fabric, savouring the tactile beauty that natural fibres and quality craftsmanship bring to the clothing. ',
            tags: ['Fashion', 'Shopping']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/Emma-Hill-wearing-navy-check-blazer-white-t-shirt-gold-layered-necklaces-round-ray-ban-sunglasses-black-belt-Chanel-vintage-timeless-bag-dark-wash-levis-501-jeans.jpg?v=1711102129&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'Your guide to party dressing this celebration season',
            excerpt: 'While the sun shines brighter during the day, the early mornings and late evenings often leave us feeling a little chilly during the spring season, reminding us not to retire our woolly layers just yet. Merino, with its exceptional ability to adapt to varying body temperatures, is therefore the perfect fibre to invest in during the warmer months – a fact well understood by local knitwear brand, Perriam.',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/FQ.co_.nz_workwear-edit_2301_Givenchy-F23-001-1365x2048_1_31ced958-20f2-4ccb-97b6-ec64b3c7aeff.jpg?v=1711102133&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: '24 hours in Dunedin with FQ’s editorial assistant',
            excerpt: 'Moving into summer, there will also be several maxi and midi dress styles arriving in lightweight fabrications accompanied by an effortlessly-cool linen suit set that invites year round versatility. Ideal pieces to be worn alone or layered together, each style is crafted to encourage the wearer to pause and feel the fabric, savouring the tactile beauty that natural fibres and quality craftsmanship bring to the clothing.',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/FQ.co_.nz_Commercial-Bay-Christmas-2_1_831e7c59-a372-4132-b87c-40901d6d097f.jpg?v=1711102133&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'The show: In conversation with Rory William Docherty',
            excerpt: 'Ideal pieces to be worn alone or layered together, each style is crafted to encourage the wearer to pause and feel the fabric, savouring the tactile beauty that natural fibres and quality craftsmanship bring to the clothing. Moving into summer, there will also be several maxi and midi dress styles arriving in lightweight fabrications accompanied by an effortlessly-cool linen suit set that invites year round versatility.',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/IMG_2510.jpg?v=1711102133&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'What you’ll find inside Fashion Quarterly’s spring issue',
            excerpt: 'Merino, with its exceptional ability to adapt to varying body temperatures, is therefore the perfect fibre to invest in during the warmer months – a fact well understood by local knitwear brand, Perriam. While the sun shines brighter during the day, the early mornings and late evenings often leave us feeling a little chilly during the spring season, reminding us not to retire our woolly layers just yet.',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/IMG_2508.jpg?v=1711102124&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'Should you be keeping your labels on the down low?',
            excerpt: 'Attendees at the Versace Fall 2023 show held in Los Angeles earlier this year were hard-pressed to spot a Medusa head; the Italian house’s famous logo featured only subtly on buttons and earrings. The clean lines of the rooftop of West Hollywood’s Pacific Design Center in West Hollywood provided the backdrop for a collection that echoed the building’s minimalist form. Instead of Versace’s typical more-is-more offering, celebrity guests (including Elton John, Dua Lipa and Cher) saw sharply tailored suits, streamlined dresses, denim and eveningwear that was glamorous yet restrained.',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/d5e915d4cc88f44b79be6b1dd0aea1ba_1.jpg?v=1711102124&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: '5 trends from Milan Fashion Week that you can wearing',
            excerpt: 'Although sculptural, even the sweeping, floor-length gowns featured a painterly polka-dot print that felt surprisingly demure when styled with satin gloves that hit just below the elbow. “I wanted to go back to the cut and shape of the clothes, to concentrate on the perfect little black dress, the perfect black suit,” said Donatella Versace of her offering — a stark contrast to what we’ve previously seen from the fashion house. Rewind to Pre-Fall 2022',
            tags: ['Style', 'Trending']
        },
        {
            url: '#',
            image: 'https://library.smind.io/cdn/shop/articles/Louise-Dunn_headshot_IMG_0976_1_bc8c2923-1179-44b2-923c-1ed545c1b4f3.png?v=1711102124&width=600',
            published_at: '13/12/2023',
            author: 'Smind',
            comments_count: 20,
            title: 'Why Olivia Cashmore’s new collection is her best one yet',
            excerpt: 'Versace collaborated with Fendi’s Kim Jones and Silvia Venturini Fendi, each swapping sides to design 25 looks for a show dubbed Fendi by Versace in a sartorial game of musical chairs that resulted in an explosion of logomania. Varying iterations of logos featured on every one of the 50 looks, in all-over prints, chunky necklaces, shoulder straps, belt buckles, jacket trim, lace, diamantés and more. It was fashion that’s unapologetically flashy, not to mention instantly recognisable.',
            tags: ['Style', 'Trending']
        },
    ]
}

const collectionJson = [
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-bags-classic-17.webp?v=1711521291&width=1200',
        url: '#',
        title: 'Bags'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-jacket-classic-08.webp?v=1711521356&width=1200',
        url: '#',
        title: 'Jacket'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-swim-classic-21.webp?v=1711521423&width=1200',
        url: '#',
        title: 'Swim'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-jacket-classic-18.webp?v=1711521312&width=1200',
        url: '#',
        title: 'Coats'
    },
    {
        image: 'https://library.smind.io/cdn/shop/files/smi-shoes-classic-22.webp?v=1711517917&width=1200',
        url: '#',
        title: 'Shoes'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-jacket-classic-18.webp?v=1711521312&width=1200',
        url: '#',
        title: 'Jean'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-shirt-classic-01.webp?v=1711521333&width=1200',
        url: '#',
        title: 'Shirt'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-dress-classic-16.webp?v=1711521385&width=1200',
        url: '#',
        title: 'Dress'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-suits-classic-26.webp?v=1711521420&width=1200',
        url: '#',
        title: 'Suits'
    },
    {
        image: 'https://library.smind.io/cdn/shop/collections/smi-suits-classic-07.webp?v=1711521409&width=1200',
        url: '#',
        title: 'Shopper bag'
    }
]

const collectionProductJson = {
    url: '#collection-product',
    products: [
        {

        }
    ]
}

export {
    textAI,
    classesAI,
    previewSectionsURL,
    localeJson,
    menuJson,
    blogJson,
    collectionJson
}